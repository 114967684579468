import 'regenerator-runtime/runtime'
import { getAppManifest as coreGetAppManifest } from './core/manifests/app-manifest'
import { getAppVersion, getEditorSentryDSN, fetcher, serializeError } from '../utils/utils'
import * as _ from 'lodash'
import * as Raven from 'raven-js'
import { initApp, startApp, getApi } from './editor-ready/editor-ready'
import {
  handleComponentDelete,
  handleFirstSave,
  handleGfppClicked,
  handlePublish,
  handlePresetChanged,
  handleAppWidgetGfppClicked,
  handleAppWidgetPasted,
  handleConnectedComponentPasted,
  handleStateChanged,
} from './on-event/on-event'
import { generateExportedApi } from './utils/utils'
import { create } from '@wix/fedops-logger'
import CoreApi from './core/core-api'
import { PlatformEvent } from '@wix/platform-editor-sdk'

Raven.config(getEditorSentryDSN(), {
  logger: 'logger-editor-app', // TODO: We need to create another logger to ADI or somehow know in sentry that some errors come from ADI
  release: getAppVersion(),
})

let biLogger
let panelDefinitions: any = {}
let isInitialized = false

const fetchSiteId = fetcher()
export const getSiteId = () => fetchSiteId.getData
export const getBiLogger = () => biLogger
export const getPanelDefinitions = () => panelDefinitions
export const setBiLogger = logger => {
  biLogger = logger
}
export const setPanelDefinitions = definitions => {
  panelDefinitions = definitions
}

let appManifest

export const fedopsLogger = create('wix-forms-editor-api')

const wrapPromiseWithRaven = (promise: Promise<any>) => {
  promise.catch(err => {
    Raven.captureException(err)
    Raven.setExtraContext()

    throw err
  })
}

export const onEvent = Raven.wrap(({ eventPayload, eventType }: PlatformEvent) => {
  switch (eventType) {
    case 'widgetGfppClicked':
      handleAppWidgetGfppClicked(eventPayload)
      break
    case 'componentGfppClicked':
      handleGfppClicked(eventPayload)
      break
    case 'componentDeleted':
      handleComponentDelete(eventPayload)
      break
    case 'siteWasPublished':
      wrapPromiseWithRaven(handlePublish())
      break
    case 'siteWasFirstSaved':
      handleFirstSave(eventPayload || {})
      break
    case 'globalDesignPresetChanged':
      handlePresetChanged(eventPayload)
      break
    case 'widgetPasted':
      handleAppWidgetPasted(eventPayload)
      break
    case 'widgetDuplicated':
      handleAppWidgetPasted(eventPayload)
      break
    case 'connectedComponentPasted':
      handleConnectedComponentPasted(eventPayload)
      break
    case 'connectedComponentDuplicated':
      handleConnectedComponentPasted(eventPayload)
      break
    case 'stateChanged':
      handleStateChanged(eventPayload)
      break
    default:
      break
  }
})

export const editorReady = Raven.wrap(async (editorSDK, appDefinitionId, payload) => {
  fedopsLogger.appLoadStarted()

  const { origin } = payload
  // TODO: Do we need all of this in ADI? use origin.initiator === 'ADI' to skip stuff we don't need
  // TODO: Check where editorSDK is being called without pm-rpc (check console, have an error)
  const msid = await editorSDK.info.getMetaSiteId(appDefinitionId)
  const siteId = await editorSDK.info.getSiteId(appDefinitionId)
  fetchSiteId.resolveData(siteId)
  const initiator = _.get(origin, 'initiator')

  Raven.setUserContext({ id: `msid_${msid}` })

  if (initiator) {
    Raven.setTagsContext({ initiator })
  }

  if (!isInitialized) {
    await initApp({ appDefinitionId, editorSDK, msid, origin, fedopsLogger })
    isInitialized = true
  }

  fedopsLogger.appLoaded()
  return startApp(origin)
})

export const getAppManifest = Raven.wrap(async () => {
  fedopsLogger.interactionStarted('get-app-manifest')
  const api: CoreApi = await getApi()
  const isResponsive = api.isResponsive()

  if (api.isADI()) {
    fedopsLogger.interactionEnded('get-app-manifest')
    return {}
  }

  if (api.isExperimentEnabled('specs.cx.FormBuilderFilterAppManifest')) {
    if (!appManifest) {
      let isTopPremium = false

      try {
        isTopPremium = await api.premium.isTopPremium()
      } catch (ex) {}

      appManifest = coreGetAppManifest({
        replaceManageFieldsWithAddFieldGffp: api.shouldReplaceManageFieldsWithAddFieldGfpp(),
        isTopPremium,
        shouldEnableCopyPasteFields: api.shouldEnableCopyPasteFields(),
        isResponsive,
      })
    }

    try {
      const filteredManifest = await api.filterAppManifestV2(appManifest)

      // console.log('original', new Blob([JSON.stringify(appManifest, null, 2)]).size / 1024, 'KB')
      // console.log(appManifest)

      // console.log('filtered', new Blob([JSON.stringify(filteredManifest, null, 2)]).size / 1024, 'KB')
      // console.log(filteredManifest)

      fedopsLogger.interactionEnded('get-app-manifest')
      return filteredManifest
    } catch (err) {
      Raven.captureException(new Error('Failed to load filtered manifest'), {
        extra: { error: serializeError(err) },
      })

      fedopsLogger.interactionEnded('get-app-manifest')
      return appManifest
    }
  }

  let isTopPremium = false

  try {
    const premiumRestrictions = await api.premium.getPremiumRestrictions()
    isTopPremium = premiumRestrictions.restrictions.isTopPremium
  } catch (ex) {}

  fedopsLogger.interactionEnded('get-app-manifest')
  return coreGetAppManifest({
    replaceManageFieldsWithAddFieldGffp: api.shouldReplaceManageFieldsWithAddFieldGfpp(),
    isTopPremium,
    shouldEnableCopyPasteFields: api.shouldEnableCopyPasteFields(),
    isResponsive,
  })
})

export const apiExports = generateExportedApi(Raven)
