import { FormField } from '../../../constants/api-types'
import { getFieldRenderConfigFields, ALL_FIELDS_DATA } from '../preset/fields/field-types-data'
import { FieldRenderConfigType } from '../preset/fields/constants'
import { FormPlugin } from '../../../constants/plugins'
import { CUSTOM_FIELD, CRM_TAGS } from '../../../constants/crm-types-tags'
import { SyncField, SyncData } from '../../../panels/contact-sync-panel/constants/types'
import * as _ from 'lodash'
import { mainCrmTypes } from '../../../panels/contact-sync-panel/constants/main-crm-types'
import { RECOMMENDED_FIELDS } from '../preset/fields/recommended-fields'
import { customTypes } from '../../../constants/field-types'
import { isCrmTypeAllowedOnce } from '../../../panels/contact-sync-panel/utils'

export const isFieldSyncable = (plugin: FormPlugin, field: FormField) => {
  const fieldConfig = getFieldRenderConfigFields([plugin], field.fieldType)
  return fieldConfig.crmSync === FieldRenderConfigType.ENABLED
}

export const getSyncedFieldsCount = (
  fields: { crmType: string; customFieldId: string }[]
): number => fields.filter(field => field.crmType !== CUSTOM_FIELD || !!field.customFieldId).length

const getSyncDataFromField = ({
  crmType,
  crmTag,
  customFieldId,
  customFieldName,
}: FormField): SyncData =>
  _.defaults(
    _.pickBy(
      {
        crmType,
        crmTag:
          crmType === CUSTOM_FIELD
            ? undefined
            : crmTag || (isCrmTypeAllowedOnce(crmType) ? CRM_TAGS.MAIN : CRM_TAGS.OTHER),
        customFieldId,
        customFieldName,
      },
      prop => prop !== null
    ),
    {
      crmType: undefined,
      crmTag: undefined,
      customFieldId: undefined,
      customFieldName: undefined,
    }
  )

export const createSyncFieldFromFormField = (field: FormField): SyncField => {
  const fieldDefinition = ALL_FIELDS_DATA[field.fieldType]
  return {
    componentRef: field.componentRef,
    customFields: fieldDefinition.customFields,
    componentType: field.componentType,
    fieldName: field.crmLabel,
    syncData: getSyncDataFromField(field),
    definitionCrmType: fieldDefinition.crmType,
  }
}

export const getMainCrmTypesCustomFields = (): { [crmType: string]: customTypes[] } =>
  mainCrmTypes.reduce((acc, crmType) => {
    const fieldPreset = RECOMMENDED_FIELDS.find(field => field.crmType === crmType)
    return {
      ...acc,
      [crmType]: fieldPreset.customFields,
    }
  }, {})

export const createSyncFieldsFromFormFields = (
  fieldsOnStage: FormField[],
  plugin: FormPlugin
): SyncField[] =>
  fieldsOnStage
    .filter(field => isFieldSyncable(plugin, field))
    .map(field => createSyncFieldFromFormField(field))
