import { ComponentConnection } from '../../../constants/api-types'
import CoreApi from '../core-api'
import Experiments from '@wix/wix-experiments'
import { ContactSyncProps } from '../../../panels/contact-sync-panel/components/contact-sync-panel'
import { SyncField } from '../../../panels/contact-sync-panel/constants/types'
import { getActivePluginFromComponentConnection } from '../plugins/utils'
import { isNewCustomFieldId, areSyncDataEqual } from '../../../panels/contact-sync-panel/utils'
import {
  getSyncedFieldsCount,
  createSyncFieldsFromFormFields,
  getMainCrmTypesCustomFields,
} from './utils'
import RemoteApi from '../../../panels/commons/remote-api'
import * as _ from 'lodash'
import { CustomFieldResponse } from '../../../constants/field-types'

export default class ContactSyncApi {
  private biLogger: any
  private boundEditorSDK: any
  private coreApi: CoreApi
  private remoteApi: RemoteApi
  private ravenInstance
  private experiments: Experiments

  constructor(
    boundEditorSDK,
    coreApi: CoreApi,
    remoteApi,
    { biLogger, ravenInstance, experiments }
  ) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.remoteApi = remoteApi
    this.ravenInstance = ravenInstance
    this.experiments = experiments
  }

  public async loadInitialPanelData({
    formComponentRef,
    componentConnection,
  }: {
    formComponentRef: ComponentRef
    componentConnection: ComponentConnection
  }): Promise<Partial<ContactSyncProps>> {
    const plugin = getActivePluginFromComponentConnection(componentConnection)

    return Promise.all([
      this.coreApi.fields.getFieldsSortByXY(formComponentRef, { allFieldsTypes: true }),
      this.remoteApi.getCustomFields(),
    ]).then(([fieldsOnStage, customFields]) => ({
      loading: false,
      fields: createSyncFieldsFromFormFields(fieldsOnStage, plugin),
      customFields,
      mainCrmTypesCustomFields: getMainCrmTypesCustomFields(),
      preset: _.get(componentConnection, 'config.preset'),
    }))
  }

  public async saveContactSyncFieldsAndReturnSyncedFieldsCount(
    fields: SyncField[],
    initialFields: SyncField[],
    customFields: CustomFieldResponse[],
    initialCustomFields: CustomFieldResponse[]
  ): Promise<{
    syncedFieldsCount: number
  }> {
    const initialCustomFieldsById = _.keyBy(initialCustomFields, 'id')
    const initialFieldsById = _.keyBy(initialFields, 'componentRef.id')

    const changedFields = fields.filter(
      field => !areSyncDataEqual(field.syncData, initialFieldsById[field.componentRef.id].syncData)
    )
    const fieldsWithNewCustomField = changedFields.filter(field =>
      isNewCustomFieldId(field.syncData.customFieldId)
    )
    const fieldsWithChangedSync = changedFields.filter(
      field => !isNewCustomFieldId(field.syncData.customFieldId)
    )

    const customFieldsToUpdate = customFields
      .filter(customField => !isNewCustomFieldId(customField.id))
      .filter(
        ({ name, id }) =>
          initialCustomFieldsById[id].name !== name &&
          _.some(fieldsWithChangedSync, field => field.syncData.customFieldId === id)
      )

    const fieldsWithCreatedNewCustomField: SyncField[] = await Promise.all(
      fieldsWithNewCustomField.map(async field => {
        const { id } = await this.remoteApi.createCustomField({
          name: field.syncData.customFieldName,
          fieldType: field.customFields[0],
        })

        return _.merge({}, field, {
          syncData: {
            customFieldId: id,
          },
        })
      })
    )

    await Promise.all(
      customFieldsToUpdate.map(({ id, name }) =>
        this.remoteApi.updateCustomFieldName({ id, newName: name })
      )
    )

    const fieldsToUpdateConfig = _.flatten([fieldsWithCreatedNewCustomField, fieldsWithChangedSync])

    await Promise.all(
      fieldsToUpdateConfig.map(field =>
        this.coreApi.setComponentConnection(field.componentRef, field.syncData, false)
      )
    )

    return {
      syncedFieldsCount: getSyncedFieldsCount(
        fields.map(({ syncData: { crmType, customFieldId } }) => ({
          crmType,
          customFieldId,
        }))
      ),
    }
  }
}
