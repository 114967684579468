import { FormsExtendApi } from './extend-api'

const DefaultFormApi = (): FormsExtendApi => {
  return {
    'settings.getCrucialElements': () => [],
    'fields.getNewFields': () => ({ add: [], remove: [] }),
  }
}

export default DefaultFormApi
