import * as _ from 'lodash'
import CoreApi from '../core-api'
import { ComponentConnection } from '../../../constants/api-types'
import { ROLE_MESSAGE, ROLE_SUBMIT_BUTTON } from '../../../constants/roles'
import { RegistrationFieldPreset, FieldPreset } from '../../../constants/field-types'
import translations from '../../../utils/translations'
import {
  CATEGORIES,
  REGISTRATION_FORM_RECOMMENDED_FIELDS,
  FIELD_GROUPS,
} from '../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { REGISTRATION_FORM_FIELDS_VALUES } from '../preset/fields/registration-form-fields'
import { ExtendApi } from './extend-api'

// TODO: Move this to single point of configuration
const CrucialFieldPreset = {
  LOGIN_EMAIL: RegistrationFieldPreset.REGISTRATION_FORM_LOGIN_EMAIL,
  PASSWORD: RegistrationFieldPreset.REGISTRATION_FORM_PASSWORD,
  LINK_TO_LOGIN: RegistrationFieldPreset.REGISTRATION_FORM_LINK_TO_LOGIN,
}

export const REGISTRATION_FORM_CRUCIAL_FIELD_PRESET_VALUES: FieldPreset[] = _.values(
  CrucialFieldPreset
)

const RegistrationFormApi = (coreApi: CoreApi): ExtendApi => {
  const getCrucialElements = async (
    componentRef: ComponentRef,
    componentConnection: ComponentConnection
  ) => {
    const { controllerRef } = componentConnection

    const missingFields = await Promise.all([
      coreApi.isFieldMissingByRole(componentRef, ROLE_MESSAGE),
      coreApi.isFieldMissingByRole(componentRef, ROLE_SUBMIT_BUTTON), // TODO: Combine to single call
      coreApi.isMissingFieldByPreset(controllerRef, CrucialFieldPreset.LOGIN_EMAIL),
      coreApi.isMissingFieldByPreset(controllerRef, CrucialFieldPreset.PASSWORD),
      coreApi.isMissingFieldByPreset(controllerRef, CrucialFieldPreset.LINK_TO_LOGIN),
    ])

    return _.filter(missingFields)
  }

  const getNewFields = () => {
    return {
      add: [
        ..._.map(REGISTRATION_FORM_FIELDS_VALUES, (type: FieldPreset) => ({
          value: type,
          name: translations.t(`fieldTypes.${type}`),
          category: CATEGORIES.registration,
        })),
        ..._.map(REGISTRATION_FORM_RECOMMENDED_FIELDS, (type: FieldPreset) => ({
          value: type,
          name: translations.t(`fieldTypes.${type}`),
          category: FIELD_GROUPS.RECOMMENDED,
          customFieldId: undefined,
        })),
      ]
    }
  }

  return {
    'settings.getCrucialElements': getCrucialElements,
    'fields.getNewFields': getNewFields,
  }
}

export default RegistrationFormApi
