import * as _ from 'lodash'
import { bindObjectFunctions } from '../../utils/utils'
import translations from '../../utils/translations'
import Experiments from '@wix/wix-experiments'
import { createCollectionsApi as createWixCodeCollectionsApi } from '@wix/wix-code-collections-api'
import * as createEditorTransportLayer from '@wix/wix-code-collections-transport-editor'
import { initBiLoggerForEditorApp } from '../../utils/bi'
import * as Raven from 'raven-js'
import RemoteApi from '../../panels/commons/remote-api'
import CoreApi from '../core/core-api'
import { createPanelDefs } from '../core/services/panel-definitions'
import { setBiLogger, setPanelDefinitions } from '../editor-app-impl'
import { openAddFormPanel } from '../core-actions'
import { fetcher } from '../../utils/utils'
import { fetchAllThemes } from '../core/preset/themes-service'
import { FedopsLogger } from '@wix/fedops-logger'
import ExperimentsService from '../../utils/experiments-service'

const fetchCoreApi = fetcher()
export const getApi = (): Promise<CoreApi> => fetchCoreApi.getData // race-condition editorReady & onEvent

const enforceFormsLimitIfNeeded = async ({
  api,
  preset,
  containerRef,
}: {
  api: CoreApi
  preset: string
  containerRef: ComponentRef
}) =>
  api.shouldEnforceFormsNumber() &&
  (await api.addForm.preventFormAdditionAddPanel(preset, containerRef))

export const startApp = async (origin: Origin) => {
  const api = await getApi()
  const originType = _.get(origin, 'info.type')

  if (originType === 'APP_MARKET') {
    return openAddFormPanel()
  }

  const preset = _.get(origin, 'info.preset')

  if (!preset) {
    // support old registration form origin object
    if (_.get(origin, 'info.type') === 'registration-form') {
      api.setEditorType('CLASSIC')
      await api.addForm.addForm('registration-form', {
        containerRef: origin.info.containerRef,
        source: 'signup',
        createCollection: false,
      })

      return api.saveSite()
    }

    return
  }

  const containerRef = origin.info.containerRef

  if (originType === 'ADD_PANEL') {
    if (await enforceFormsLimitIfNeeded({ api, preset, containerRef })) {
      return
    }

    return api.addForm.addForm(preset, {
      containerRef: origin.info.containerRef,
      source: originType,
      shouldSelectForm: _.get(origin, 'info.select_form', false),
    })
  }

  await api.addForm.addForm(preset, {
    containerRef,
    source: originType,
    createCollection: false,
  })

  return api.saveSite()
}

export const initApp = async ({
  appDefinitionId,
  editorSDK,
  msid,
  origin,
  fedopsLogger,
}: {
  appDefinitionId: string
  editorSDK: EditorSDK
  msid: string
  origin: Origin
  fedopsLogger: FedopsLogger
}) => {
  const experiments: Experiments = new Experiments({ baseUrl: 'https://www.wix.com' })
  await experiments.load('wix-form-builder')

  ExperimentsService.init(experiments)

  const boundEditorSDK = bindObjectFunctions(editorSDK, appDefinitionId)
  const transportLayer = createEditorTransportLayer(editorSDK, appDefinitionId)
  const collectionsApi = createWixCodeCollectionsApi({ transportLayer })

  const remoteApi = new RemoteApi({ boundEditorSDK, experiments, ravenInstance: Raven })
  const biLogger = await initBiLoggerForEditorApp(msid, origin.type)

  const coreApi = new CoreApi(boundEditorSDK, editorSDK, {
    apis: {
      collectionsApi,
      remoteApi,
    },
    experiments,
    ravenInstance: Raven,
    biLogger,
    origin,
    appDefinitionId,
    fedopsLogger,
  })

  const [locale] = await Promise.all([
    boundEditorSDK.environment.getLocale(),
    boundEditorSDK.editor.setAppAPI(coreApi.generateRuntimeApi()),
  ])

  await translations.init(locale, experiments.all())

  setBiLogger(biLogger)

  coreApi.appState.setState()
  fetchCoreApi.resolveData(coreApi)

  const panelDefinitions = createPanelDefs(msid)
  setPanelDefinitions(panelDefinitions)

  fetchAllThemes(Raven)

  coreApi.steps.updateMultiStepFormsTitles()
}
