import * as _ from 'lodash'
import { RegistrationFieldPreset } from '../../../../../../constants/field-types'
import { FIELDS } from '../../../../../../constants/roles'
import { CheckboxAgreeTerms } from './checkbox-agree-terms'
import { FormPlugin } from '../../../../../../constants/plugins'
import { FieldRenderConfigType } from '../../constants'

export class CheckboxJoinCommunity extends CheckboxAgreeTerms {
  protected get renderConfig() {
    return {
      [FormPlugin.REGISTRATION_FORM]: {
        required: FieldRenderConfigType.ENABLED,
        checkedByDefault: FieldRenderConfigType.ENABLED,
      },
    }
  }

  public get disableCollectionSync() {
    return false
  }

  public get fieldType() {
    return RegistrationFieldPreset.REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY
  }

  protected get data() {
    return {
      label: this.translate(`preset.${_.camelCase(this.fieldType)}Label`),
    }
  }

  protected get props() {
    return {
      required: false,
    }
  }
}
