import { FormsFieldPreset } from '../../../constants/field-types'
import { ExtendApi } from './extend-api'

const GetSubscribersApi = (): ExtendApi => {
  const getNewFields = () => {
    return {
      remove: [FormsFieldPreset.GENERAL_SUBSCRIBE]
    }
  }

  return {
    'fields.getNewFields': getNewFields,
  }
}

export default GetSubscribersApi
