import Experiments, { ExperimentsBag } from '@wix/wix-experiments'
import * as Raven from 'raven-js'

class ExperimentsService {
  private _experiments: Experiments
  private _ravenInstance: RavenInstance

  constructor() {
    this._ravenInstance = Raven
  }

  init(experiments: Experiments) {
    this._experiments = experiments
  }

  initByExperimentsBag(experimentsBag: ExperimentsBag) {
    this._experiments = new Experiments({ experiments: experimentsBag })
  }

  isEnabled(key: string): boolean {
    if (!this._experiments) {
      this._ravenInstance.captureMessage('Experiments not initialized')
      return false
    }

    return this._experiments.enabled(key)
  }
}

export default new ExperimentsService()
