import { COMPONENT_TYPES } from '../../../../constants/component-types'

export const SUPPORTED_COMPONENT_TYPES_IN_RESPONSIVE = [
  COMPONENT_TYPES.TEXT_INPUT,
  COMPONENT_TYPES.TEXT_AREA_INPUT,
  COMPONENT_TYPES.FILE_UPLOADER,
  COMPONENT_TYPES.RADIO_GROUP,
  COMPONENT_TYPES.CHECKBOX_GROUP,
  COMPONENT_TYPES.DATE_PICKER,
  COMPONENT_TYPES.SINGLE_CHECKBOX,
  COMPONENT_TYPES.COMBOBOX
]

export enum ADD_FIELD_FLOW {
  ADD_NEW_CLASSIC_FIELD = 'ADD_NEW_CLASSIC_FIELD',
  ADD_NEW_ADI_FIELD = 'ADD_NEW_ADI_FIELD',
  DUPLICATE_CLASSIC_FIELD = 'DUPLICATE_CLASSIC_FIELD',
  DUPLICATE_ADI_FIELD = 'DUPLICATE_ADI_FIELD'
}
